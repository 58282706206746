import React from 'react'

import { importAll } from '../utils/helpers'

import SignupForm from './SignupForm'
import {
  Section,
  SectionTitle,
  SectionTitleHeadings,
  ContainedRow,
  IconList,
  CtaHero
} from './UI'

const pageImgs = importAll(require.context('../img/proof', false, /\.(png|jpe?g|svg)$/))

const Hero = props => (
  <Section {...props} className='hero'>
    <CtaHero
      {...props}
      containerClass='background-proof'
      contentClass='col-md-6'
      buttonClass='btn-light'
    />
  </Section>
)

const Benefits = props => (
  <Section {...props} className='benefits'>
    <SectionTitle {...props} />
    <IconList {...props} pageImgs={pageImgs} />
  </Section>
)

const SignUp = props => (
  <Section {...props} className='signup'>
    <ContainedRow>
      <SectionTitleHeadings
        {...props}
        className='section-title col-12 col-md-10 col-lg-6 col-xl-7'
      />
      <div className='signup-form col-12 col-md-10 col-lg-6 col-xl-5'>
        <SignupForm />
      </div>
    </ContainedRow>
  </Section>
)

export {
  Hero,
  Benefits,
  SignUp
}
