import React from 'react'
import classnames from 'classnames'

class Form extends React.Component {
  constructor (props) {
    super(props)

    this.classes = classnames(
      this.props.className,
      {
        'needs-validation': this.props.validate
      }
    )
  }

  render () {
    return (
      <form
        className={this.classes}
        noValidate={this.props.validate}
        action={this.props.action}
        method={this.props.method}
        onSubmit={this.props.handleSubmit}
      >
        {this.props.children}
      </form>
    )
  }
}

const commonFormProps = props => (
  {
    id: props.name,
    name: props.name,
    value: props.value,
    placeholder: props.placeholder,
    onChange: props.handleChange,
    required: props.required
  }
)

const FormGroup = props => {
  const classes = classnames(
    'form-group',
    props.groupClass,
    {
      'form-group-required': props.required
    }
  )

  return (
    <div className={classes}>
      {props.children}
    </div>
  )
}

const FormLabel = props => (
  props.label
    ? <label htmlFor={props.name}>{props.label}</label>
    : null
)

const InputText = props => (
  <FormGroup {...props}>
    <FormLabel {...props} />
    <input
      type={props.type}
      className='form-control'
      {...commonFormProps(props)}
      {...props.attributes}
    />
  </FormGroup>
)

const InputSelect = props => (
  <FormGroup {...props}>
    <FormLabel {...props} />
    <select
      className='custom-select custom-select-lg'
      {...commonFormProps(props)}
    >
      <option disabled />
      {props.options.map((option, index) =>
        <option value={option} key={index}>
          {option}
        </option>
      )}
    </select>
  </FormGroup>
)

const InputTextArea = props => (
  <FormGroup {...props}>
    <FormLabel {...props} />
    <textarea
      className='form-control'
      {...commonFormProps(props)}
    />
  </FormGroup>
)

export {
  Form,
  FormGroup,
  InputText,
  InputSelect,
  InputTextArea
}
