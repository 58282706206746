import React from 'react'

import {
  Form,
  InputText
} from './Forms'

class SignupForm extends React.Component {
  constructor (props) {
    super(props)

    this.initialState = {
      email: ''
    }
    this.state = this.initialState

    this.boundHandleChange = this.handleChange.bind(this)
    this.boundHandleSubmit = this.handleSubmit.bind(this)
  }

  handleChange (event) {
    const newState = {}
    newState[event.target.name] = event.target.value
    this.setState(newState)
  }

  handleSubmit (event) {
    const form = event.target
    const { email } = this.state
    const formData = JSON.stringify({ email })
    event.preventDefault()

    if (form.checkValidity() === false) {
      event.stopPropagation()
      form.classList.add('was-validated')
      return
    }

    // Validate honeypot
    const hp = document.getElementById('lastName')
    if (hp.value.length) {
      return
    }

    fetch(form.action, {
      method: form.method,
      headers: {
        'Accept': 'application/json'
      },
      body: formData
    }).then((response) => {
      if (response.status === 200) {
        this.setState({
          submitSuccess: true,
          ...this.initialState
        })

        setTimeout(() => this.setState({
          submitSuccess: null
        }), 3000)
      } else {
        this.setState({
          submitSuccess: false
        })
      }
    })
  }

  render () {
    return (
      <div>
        <Form
          className='form-signup form-inline'
          action=' https://hooks.zapier.com/hooks/catch/502437/f0oec4/'
          method='POST'
          handleSubmit={this.boundHandleSubmit}
          validate
        >
          <InputText
            name='email'
            type='email'
            value={this.state.email}
            placeholder='Enter your email'
            handleChange={this.boundHandleChange}
            required
          />

          <InputText
            name='lastName'
            type='text'
            groupClass='bumble-bee'
            value={this.state.lastName}
            handleChange={this.boundHandleChange}
            attributes={{tabIndex: -1, 'aria-hidden': true}}
          />

          <button type='submit' className='btn btn-primary'>Signup</button>
        </Form>

        {
          this.state.submitSuccess &&
            <div className='form-success' role='alert'>
              Success! We’ll be in touch.
            </div>
        }

        {
          this.state.submitSuccess === false &&
            <div className='alert alert-danger' role='alert'>
              There was a problem with your submission. Please try again.
            </div>
        }
      </div>
    )
  }
}

export default SignupForm
