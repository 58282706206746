import React from 'react'
import Helmet from 'react-helmet'

import { Hero, Benefits, SignUp } from '../components/Proof'
import Layout from '../components/layout'

const About = props => {
  const { benefits } = props.data.contentJson.proof

  return (
    <Layout location={props.location}>
      <div className='page-proof'>
        <Helmet
          title={`Proof - Blockchain Proof Engine`}
          meta={[
            {
              name: 'description',
              content: 'Prove the integrity and timestamp of data without depending on a trusted third-party.'
            }
          ]}
        />

        <Hero
          linkHref='https://proof.com'
          linkText='Visit Proof.com'
          title='For business'
          subtitle='Proof'
          text='Prove the integrity and timestamp of data without depending on a trusted third-party.'
        />

        <Benefits title='Proof makes it simple to have complete confidence in your data' featureList={benefits} />
        <SignUp title='Proof launches soon' subtitle='Be notified when it does.' />

      </div>
    </Layout>
  )
}

export default About

export const pageQuery = graphql`
  query ProofPageQuery {
    site {
      siteMetadata {
        title
      }
    }
    contentJson {
      proof {
        benefits {
          icon
          title
          text
        }
      }
    }
  }
`
